import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom"; // Importe 'Routes' também
import { Analytics } from '@vercel/analytics/react';

import Portugues from "./pt";
import Ingles from "./en";
import Privacy from "./privacy";
import Privacidade from "./privacidade";
import Rtn from "./rtn";
import RtnPt from "./rtn-pt";

const RoutesComponent = () => { // Renomeado para evitar conflitos com o import 'Routes'
    return (
        <BrowserRouter>
            <Routes> {/* 'Routes' usado corretamente aqui para envolver os componentes 'Route' */}
                <Route path="/" element={<Portugues />} exact />
                <Route path="/en" element={<Ingles />} />
                <Route path="/privacidade" element={<Privacidade />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/rtn" element={<Rtn />} />
                <Route path="/rtn-pt" element={<RtnPt />} />
            </Routes>
            <Analytics />
        </BrowserRouter>
    );
};

export default RoutesComponent;

import React, { useState, useRef } from 'react';
import { dados } from './dados';
import './styles/style.css'

export default function Portugues() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(dados[0]);
  const [randomPic, setRandomPic] = useState(Math.floor(Math.random() * 3) + 1)
  const modalContentRef = useRef(null);

  const openModal = (dataId) => {
    setSelectedData(dados[dataId]);
    setIsModalOpen(true);
  };

  const closeModal = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };


  return (
    <>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className='modal-border'>
            <div className="modal-content" ref={modalContentRef}>
              <div className='header'>
                <img className="imagem" src={selectedData[0]} alt={selectedData[1]} />
                <div className="titulo">{selectedData[1]}</div>
              </div>

              {/* <div className='nivel-de-conhecimento'>
                <div className="titulo">nível de conhecimento:</div>
                <img className="estrelas" src={selectedData[2]} alt="estrelas" />
              </div> */}

              {selectedData[3].length > 0 ?
                <div className='certificados'>
                  <div className="titulo">certificados:</div>
                  <ul>
                    {selectedData[3].map((certificado, idx) => (
                      <li key={idx}><a href={certificado} target="_blank" rel="noopener noreferrer">{`Certificado ${idx + 1}`}</a></li>
                    ))}
                  </ul>
                </div> : null}

            {selectedData[4].length > 0 ?
              <div className='projetos-modal'>
                <div className="titulo">projetos:</div>
                <ul>
                  {selectedData[4].map((projeto, idx) => (
                    <li key={idx}><a href={projeto[1]} target="_blank" rel="noopener noreferrer">{projeto[0]}</a></li>
                  ))}
                </ul>
              </div> : null }
              
            </div>
          </div>
        </div>
      )}
      <div className="home">
        <a className="ingles" href="/en"><img src="./assets/icones/usabew.png" alt="en-US" /></a>
        {/*<div className="texto">Oi, eu sou o Caio</div>*/}
        <div className="icones">
          <a target="_blank" rel="noreferrer" href="https://bit.ly/vote-rtn"
          ><img src="./assets/icones/twitter.svg" alt="twitter"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://github.com/caioagralemos"
          ><img src="./assets/icones/github.svg" alt="github"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://linkedin.com/in/caioagralemos"
          ><img src="./assets/icones/linkedin.svg" alt="linkedin"
            /></a>
        </div>
        <img src={"./assets/profile" + randomPic + ".webp"} class="profile" alt="profile" />
      </div>
      <div className="sobremim">
        <div className="titulo">SOBRE MIM</div>
        <div className="informacoes">
          <div className="info">
            <img src="./assets/icones/pc.png" alt="pc" />Aprendi a ler com 3 anos
            e desde então sou apaixonado por tecnologia, computação e design.
          </div>
          <div class="info">
            <img src="./assets/icones/hobbies.png" alt="hobbies" />Meus hobbies são viagens, jogos, esportes e música, e criatividade e inovação são minhas características favoritas.
          </div>
          <div className="info">
            <img src="./assets/icones/college.png" alt="college" />Curso Ciências
            da Computação na UFAL e tenho mais de 30 certificados
            extra-curriculares.
          </div>
          <div class="info">
            <img src="./assets/icones/ssc.png" alt="swift student challenge" />Fui reconhecido pela Apple como um dos melhores estudantes dev-iOS do mundo <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=iuo-ZkgB_Eg&list=PLBCWDKqK8_I14sLrbM8ZH7gHS9tUkKOA_"
            >na WWDC 2024</a> com o "Potoca".
          </div>
        </div>
      </div>
      <div className="conhecimentos">
        <div className="titulo">CONHECIMENTOS</div>
        <div className="subtitulo">clique nos ícones pra saber mais</div>
        <div className="gridContainer">
          <button className="conhecimento" onClick={() => openModal(0)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/django.png" alt="Django" />
          </button>
          <button className="conhecimento" onClick={() => openModal(1)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/express.webp" alt="Express" />
          </button>
          <button className="conhecimento" onClick={() => openModal(2)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/figma.png" alt="Figma" />
          </button>
          <button className="conhecimento" onClick={() => openModal(3)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/firebase.webp" alt="Firebase" />
          </button>
          <button className="conhecimento" onClick={() => openModal(4)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/java.png" alt="Java" />
          </button>
          <button className="conhecimento" onClick={() => openModal(5)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/js.png" alt="Javascript" />
          </button>
          <button className="conhecimento" onClick={() => openModal(6)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/mongodb.webp" alt="MongoDB" />
          </button>
          <button className="conhecimento" onClick={() => openModal(7)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/mysql.png" alt="MySQL" />
          </button>
          <button className="conhecimento" onClick={() => openModal(8)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/next.png" alt="Next" />
          </button>
          <button className="conhecimento" onClick={() => openModal(9)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/postgres.png" alt="Postgres" />
          </button>
          <button className="conhecimento" onClick={() => openModal(10)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/py.png" alt="Python" />
          </button>
          <button className="conhecimento" onClick={() => openModal(11)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/react.webp" alt="React" />
          </button>
          <button className="conhecimento" onClick={() => openModal(12)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/sass.webp" alt="SASS" />
          </button>
          <button className="conhecimento" onClick={() => openModal(13)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/swift.webp" alt="Swift" />
          </button>
          <button className="conhecimento" onClick={() => openModal(14)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/swiftdata.webp" alt="SwiftData" />
          </button>
        </div>
      </div>
      <div className="projetos">
        <div className="titulo">PROJETOS</div>
        <div className="subtitulo">clique nos ícones pra saber mais</div>
        <div className="icones">
          <a target="_blank" rel="noreferrer" href="https://agralemos.com.br" className="projeto"
          ><img className="icone" src="./assets/icones/agralemos.png" alt="agra lemos"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://bit.ly/cal-bill" className="projeto"
          ><img className="icone" src="./assets/icones/bill.png" alt="bill"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://bit.ly/btestate" className="projeto"
          ><img className="icone" src="./assets/icones/realestate.png" alt="real estate"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://espacogouvea.com.br" className="projeto"
          ><img className="icone" src="./assets/icones/espacogouvea.png" alt="espaço gouvêa"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://jogbola.com" className="projeto"
          ><img className="icone" src="./assets/icones/jogbola.png" alt="jogbola"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://github.com/caioagralemos/Pagina" className="projeto"
          ><img className="icone" src="./assets/icones/pagina.png" alt="página"
            /></a>
        </div>
        <div className='icones'>
          <a target="_blank" rel="noreferrer" href="https://bit.ly/playpotoca" className="projeto"
          ><img className="icone" src="./assets/icones/potoca.png" alt="potoca"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://revisai.co" className="projeto"
          ><img className="icone" src="./assets/icones/revisai.png" alt="revisai"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://caioagralemos.com/rtn-pt" className="projeto"
          ><img className="icone" src="./assets/icones/rtn.png" alt="rtn"
            /></a>
          <a target="_blank" rel="noreferrer" href="http://www.mydjango.kinghost.net/sistema_de_consultas/" className="projeto"
          ><img className="icone" src="./assets/icones/hu.png" alt="sistema de consultas"
            /></a>
          <a target="_blank" rel="noreferrer" href="http://bit.ly/cal-tabooada" className="projeto"
          ><img className="icone" src="./assets/icones/tabooada.png" alt="tabooada"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://www.udemy.com/course/primeiro-contato-com-programacao-python" className="projeto"
          ><img className="icone" src="./assets/icones/udemycourse.png" alt="udemy course"
            /></a>
        </div>
      </div>
      <div className="contato">
        <div className="card">
          <div className="inicio">
            <div className="imagem">
              <img src="./assets/profile-ft.webp" alt="foto de perfil" />
            </div>
            <div className="texto">
              <div className="titulo">Caio Agra Lemos</div>
              <div className="subtitulo">desenvolvedor de software</div>
            </div>
          </div>
          <div className="final">
            <div className="icones">
              <a target="_blank" rel="noreferrer" href="https://instagram.com/caioagralemos"
              ><img src="./assets/icones/insta2.png" alt="instagram"
                /></a>
              <a target="_blank" rel="noreferrer" href="https://wa.me/5582991220020"
              ><img src="./assets/icones/whatsapp.png" alt="whatsapp"
                /></a>
              <a target="_blank" rel="noreferrer" href="mailto:caioagralemos@live.com"
              ><img src="./assets/icones/email.png" alt="email"
                /></a>
            </div>
            <div className="cv">
              <a href="./assets/pt-resume.pdf" download="pt-resume.pdf"><img src="./assets/icones/cv.png" alt="download cv" /></a>
              <a href="./assets/pt-portfolio.pdf" download="pt-portfolio.pdf"><img src="./assets/icones/pf.png" alt="download portfolio" /></a>            </div>
          </div>
        </div>

        <div className="footer">
          © Caio Agra Lemos. All rights reserved
          <div className="nonstop">Nonstop</div>
        </div>
      </div>
    </>
  );
}